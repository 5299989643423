import { title } from "assets/jss/material-kit-react.jsx";

const productStyle = {
  section: {
    padding: "70px 0",
    textAlign: "center"
  },
  valuseSection: {
    padding: "0 0 70px 0",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  titlewhite: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    color: "#fff"
  },
  description: {
    color: "#999",
    fontFamily: '"Segoe UI", "Helvetica", "Arial", sans-serif'
  },
  descriptionwhite: {
    color: "#fff",
    fontFamily: '"Segoe UI", "Helvetica", "Arial", sans-serif'
  },
  bwimage: {
    padding: "20px 0 20px 0",
    filter: "grayscale(1)",
    '&:hover': {
      filter: "none !important"
    }
  }
};

export default productStyle;
